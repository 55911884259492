import styled from 'styled-components';
import { device } from 'theme';
import Banner from 'assets/images/bg/black-gradient-hero-desktop.png';
import MobileBg from 'assets/images/bg/black-gradient-banner-mobile.png';

export const HeroAboutWrapStyle = styled.div`
  background-image: url(${Banner});
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: left;
  transition: ${(props) => props.theme.transition};
  background-color: ${(props) => props.theme.colors.white};

  @media ${device.large} {
    background-position: 100% 0%;
  }

  @media ${device.medium} {
    background-image: url(${Banner});
    background-position: 100% 0%;
  }
  @media ${device.small} {
    background-position: center;
    background-size: 100% 100%;
    background-image: url(${MobileBg});
  }
`;
