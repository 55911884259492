import React, { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'components/ui/wrapper';
import Text from 'components/ui/text';
import Button from 'components/ui/button';
import { SectionWrapper } from './section-text-content.style';
import SectionTitle from 'components/ui/section-title';

export interface Props {
  className?: string;
  header?: {
    title?: string;
    subtitle?: string;
  };
  body?: string;
  button?: {
    link: string;
    title: string;
  };
  sectionTitleStyle?: any;
  sectionBodyStyle?: any;
  btnStyle?: any;
}

const SectionTextContent: FC<Props> = ({
  className,
  header,
  body,
  button,
  sectionTitleStyle,
  sectionBodyStyle,
  btnStyle,
}) => (
  <SectionWrapper className={className}>
    <Container>
      <Row>
        <Col lg={10} md={10} xs={10} align="center" className="offset-1">
          <>
            {header && (header.title || header.subtitle) && (
              <SectionTitle
                title={header.title}
                subtitle={header.subtitle}
                {...sectionTitleStyle}
              />
            )}
            {body && (
              <Text {...sectionBodyStyle}>
                <ReactMarkdown source={body} />
              </Text>
            )}
            {button && button.title && button.link && (
              <Button {...btnStyle} to={button.link} varient="texted">
                {button.title}
              </Button>
            )}
          </>
        </Col>
      </Row>
    </Container>
  </SectionWrapper>
);

SectionTextContent.propTypes = {
  sectionTitleStyle: PropTypes.object,
  btnStyle: PropTypes.object,
};

SectionTextContent.defaultProps = {
  sectionTitleStyle: {
    mb: '30px',
    align: 'center',
  },
  btnStyle: {
    width: '100%',
    align: 'center',
    m: '16px 0 0',
  },
  sectionBodyStyle: {},
};

export default SectionTextContent;
