import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import SectionTextContent from 'components/section-text-content';

const InvestmentsArea = ({ sectionTitleStyle }) => {
  const data = useStaticQuery(graphql`
    query homePageInvestmentsAreaQueryData {
      json: homePageInvestmentsJson {
        header {
          title
          subtitle
        }
        body
        button {
          title
          link
        }
      }
    }
  `);

  return (
    <SectionTextContent
      {...data.json}
      sectionTitleStyle={sectionTitleStyle}
      className={'right-grey-gradient'}
    />
  );
};

InvestmentsArea.propTypes = {
  titleStyle: PropTypes.object,
  subtitleStyle: PropTypes.object,
  btnStyle: PropTypes.object,
};

InvestmentsArea.defaultProps = {
  sectionTitleStyle: {
    mb: '30px',
    align: 'center',
  },
};

export default InvestmentsArea;
