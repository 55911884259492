import React, { useEffect, useRef, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Container, Row, Col } from 'components/ui/wrapper';
import { GifPlayer, Section, VideoPlayer } from './hero-area.style';
import AnimationWebm from 'assets/images/landing-animation.webm';
import animationGif from 'assets/images/500x500.gif';
import HeroBanner from 'components/hero-banner';

const HeroArea = () => {
  const data = useStaticQuery(graphql`
    query homePageHeroQueryData {
      json: allHomePageHeroJson {
        nodes {
          id
          logo {
            publicURL
          }
          title
          body
          button {
            title
            link
          }
        }
      }
    }
  `);

  const items = data.json.nodes;

  if (!items) {
    return null;
  }

  const vidEl = useRef<HTMLVideoElement>(null);
  const [fallbackToGif, setFallback] = useState(false);

  useEffect(() => {
    setFallback(() => {
      const isChrome =
        window.navigator.userAgent.toLowerCase().indexOf('chrome') > -1;
      const isSafari =
        window.navigator.userAgent.toLowerCase().indexOf('safari') > -1;
      return !isChrome && isSafari;
    });

    const sources = vidEl?.current?.querySelectorAll('source');
    const lastsource = sources && sources[sources.length - 1];

    const onErrorHandler = () => {
      setFallback(true);
    };

    lastsource?.addEventListener('error', onErrorHandler);

    return lastsource?.removeEventListener('error', onErrorHandler);
  }, [setFallback]);

  return (
    <Section>
      <Container>
        <Row alignitems="center">
          <Col md={6}>
            <HeroBanner bannerItems={items} />
          </Col>
          <Col md={6}>
            {fallbackToGif ? (
              <GifPlayer src={animationGif} />
            ) : (
              <VideoPlayer autoPlay loop muted playsInline ref={vidEl}>
                <source src={AnimationWebm} type="video/webm" />
                <img src={animationGif} />
              </VideoPlayer>
            )}
          </Col>
        </Row>
      </Container>
    </Section>
  );
};

export default HeroArea;
