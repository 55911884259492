import React, { FC, MutableRefObject, useEffect, useRef, useState } from 'react';
import { FadeInContainer } from './fade-in.styles';

export const FadeIn: FC<{}> = ({ children }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [visible, setIsVisible] = useState(false);

  useEffect(() => {
    const scrollHandler = () => {
      if(ref){
        if (window.innerHeight > (ref as MutableRefObject<HTMLDivElement>).current?.getBoundingClientRect().top) {
          setIsVisible(true);
        }
      }
    };

    window.addEventListener('scroll', scrollHandler);

    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, [ref, setIsVisible]);

  return (
    <FadeInContainer ref={ref} visible={visible}>
      {children}
    </FadeInContainer>
  );
};

export default FadeIn;
