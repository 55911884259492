import styled, { css } from 'styled-components';

export const FadeInContainer = styled.div<{ visible?: boolean} >`
  opacity: 0;
  ${(props) =>
    props.visible &&
    css`
      animation: 3s fadeIn forwards;
      @keyframes fadeIn {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
    `}
`;
