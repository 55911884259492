import styled from 'styled-components';
import { device } from 'theme';

export const SectionWrap = styled.section`
  padding-top: 100px;
  padding-bottom: 91px;
  @media ${device.medium} {
    background-image: none;
    padding-top: 80px;
    padding-bottom: 21px;
  }
  @media ${device.medium} {
    padding-top: 60px;
    padding-bottom: 0;

    .swiper-pagination {
      display: none !important;
    }
  }
`;

export const SectionTitleWrap = styled.div`
  margin-bottom: 53px;
  @media ${device.small} {
    margin-bottom: 30px;
  }
`;

export const StepWrap = styled.div`
  text-align: right;
  h3 {
    position: relative;
    font-size: 13px;
    line-height: 2em;
    letter-spacing: 2px;
    text-transform: uppercase;
    display: inline-block;
    padding: 35px 80px 35px 42px;
    z-index: 1;
    mark {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(0, -50%);
      font-size: 120px;
      line-height: 0.8;
      font-weight: 900;
      color: #ededed;
      z-index: -1;
    }
    &:before {
      background-color: ${(props) => props.theme.colors.themeColor};
      content: '';
      position: absolute;
      top: 47px;
      left: 0;
      width: 34px;
      height: 1px;
    }
  }
  @media ${device.medium} {
    h3 {
      mark {
        font-size: 72px;
      }
    }
  }
  @media ${device.small} {
    text-align: left;
    margin-bottom: 42px;
    h3 {
      mark {
        font-size: 64px;
        left: 0;
      }
    }
  }
`;
