import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import SectionRightImage from 'components/section-right-image';

const AboutUsArea = () => {
  const data = useStaticQuery(graphql`
    query homePageAboutUsQuery {
      json: homePageAboutUsJson {
        image {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
              presentationHeight
            }
          }
        }
        aboutUs {
          contents {
            title
            body
          }
          link {
            title
            link
          }
        }
      }
    }
  `);

  const { aboutUs } = data.json;

  return (
    <SectionRightImage
      {...aboutUs}
      image={data.json.image}
      className="left-shadow-slice"
    />
  );
};

export default AboutUsArea;
