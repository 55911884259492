import Gradiation from 'components/ui/gradiation';
import Heading, { HeadingStyles } from 'components/ui/heading';
import SwiperSlider from 'components/ui/swiper';
import { Col, Container, Row } from 'components/ui/wrapper';
import React, { FC } from 'react';
import parse from 'html-react-parser';
import { SectionWrap, SectionTitleWrap } from './steps.style';
import { StyledComponentsBaseStylingProps } from 'theme';

export interface StepType {
  indicator: string;
  title: string;
  body: string;
}

export interface Props {
  subtitle?: string;
  title?: string;
  steps: StepType[];
  slider?: any;
  className?: string;
  titleStyle?: HeadingStyles;
  subtitleStyle?: StyledComponentsBaseStylingProps;
  gradiationStyle?: {
    titleStyle?: StyledComponentsBaseStylingProps;
    descStyle?: StyledComponentsBaseStylingProps;
  };
  sliderStyle?: any;
}

const StepsSection: FC<Props> = ({
  subtitle,
  title,
  steps,
  slider,
  titleStyle,
  subtitleStyle,
  gradiationStyle,
  sliderStyle,
  className,
}) => (
  <SectionWrap className={className}>
    <Container>
      <Row>
        <Col md={6}>
          <SectionTitleWrap>
            {subtitle && <Heading {...subtitleStyle}>{subtitle}</Heading>}
            {title && <Heading {...titleStyle}>{parse(title) as string}</Heading>}
          </SectionTitleWrap>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <SwiperSlider {...sliderStyle} settings={slider}>
            {steps.map((step: StepType, i) => (
              <div className="item" key={`steps-${i}`}>
                <Gradiation
                  key={`steps-${i}`}
                  number={step.indicator}
                  isLast={i === steps.length - 1}
                  title={step.title}
                  desc={step.body}
                  {...gradiationStyle}
                />
              </div>
            ))}
          </SwiperSlider>
        </Col>
      </Row>
    </Container>
  </SectionWrap>
);

StepsSection.defaultProps = {
  titleStyle: {
    as: 'h3',
    child: {
      color: 'primary',
    },
  },
  subtitleStyle: {
    as: 'h6',
    color: '#b0b0b0',
    fontSize: '18px',
    letterspacing: '3px',
    texttransform: 'uppercase',
    lineHeight: '40px',
    mt: '-5px',
    mb: '20px',
  },
  gradiationStyle: {
    titleStyle: {
      fontSize: '18px',
      color: '#333',
    },
    descStyle: {
      fontSize: '14px',
      lineHeight: '25px',
      color: '#868686',
    },
  },
  slider: {
    slidesPerView: 4,
    loop: false,
    noSwiping: true,
    spaceBetween: 0,
    grabCursor: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    breakpoints: {
      320: {
        direction: 'vertical',
        slidesPerView: 'auto',
      },
      992: {
        direction: 'horizontal',
        slidesPerView: 4,
        noSwiping: false,
      },
    },
  },
  sliderStyle: {
    pagination: {
      mt: '28px',
    },
  },
};

export default StepsSection;
