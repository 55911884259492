import styled, { css } from 'styled-components';

export const BannerItem = styled.li<{ isSelected?: boolean }>`
  display: none;
  display: ${(props) => props.isSelected && 'block'};
  color: ${(props) => props.theme.colors.white};
  animation: 6.5s linear 0s fadein;
  width: 100%;

  @keyframes fadein {
    0% {
      opacity: 0;
      margin-left: -5%;
    }
    15% {
      opacity: 1;
      margin-left: 0;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .hero-banner-cta {
    transition: ${(props) => props.theme.transition};
    color: ${(props) => props.theme.colors.white};
  }

  .hero-banner-img {
    height: auto;
    max-width: 350px;
  }
`;

export const BannerWrapper = styled.ul`
  min-height: 450px;
  display: flex;
  align-items: center;
`;

export const DotsContainer = styled.fieldset`
  display: flex;
  align-items: center;
  justify-content: start;
  margin-top: 15px;
`;

export const DotButtonWrapper = styled.button`
  outline: none;
  border: none;
  background: none;
  color: none;
  margin: 0 5px;
  padding: 10px;
`;

export const Dot = styled.div<{ filled?: boolean }>`
  outline: none;
  display: block;
  background: none;
  padding: 5px;
  border-radius: 50%;
  border: 1px solid white;
  height: 10px;
  width: 10px;
  margin: 5px;
  cursor: pointer;
  ${(props) =>
    props.filled &&
    css`
      background: ${props.theme.colors.white};
    `};
`;
