import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import StepsSection from 'components/steps-section';

const TimelineArea = () => {
  const data = useStaticQuery(graphql`
    query HomePageTimelineAreaQuery {
      json: homePageTimelineJson {
        heading {
          title
          subtitle
        }
        steps {
          number
          content {
            title
            body
          }
        }
      }
    }
  `);
  if (!data.json) {
    return null;
  }

  const {
    heading: { title, subtitle },
    steps,
  } = data.json;

  const displayedSteps = steps.map((step) => ({
    ...step.content,
    indicator: step.number,
  }));

  return (
    <StepsSection
      title={title}
      subtitle={subtitle}
      steps={displayedSteps}
      className={'left-red-slice md-no-bg-image'}
    />
  );
};

export default TimelineArea;
