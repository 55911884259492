import styled from 'styled-components';
import { device } from 'theme';

export const Section = styled.section`
  padding: 75px 0 50px 0;

  margin-left: 25px;

  @media ${device.small} {
    padding: 75px 40px 30px 0;
    margin: 0 80px 0 15px;
  }
`;

export const VideoPlayer = styled.video`
  filter: grayscale(1);
`;

export const GifPlayer = styled.img`
  filter: grayscale(1);
`;
