import React from 'react';
import { HeroAboutWrapStyle } from './hero-about-wrap.style';

interface HeroAboutWrapProps {
  children: JSX.Element | JSX.Element[];
}

const HeroAboutWrap = ({ children }: HeroAboutWrapProps) => {
  return <HeroAboutWrapStyle>{children}</HeroAboutWrapStyle>;
};

export default HeroAboutWrap;
