import React from 'react';
import {
  GradiationWrap,
  Line,
  NumberWrap,
  ContentWrap,
  Heading,
  Text,
} from './gradiation.style';
import ReactMarkdown from 'react-markdown';

const Gradiation = ({ number, title, desc, ...restProps }) => {
  const { titleStyle, descStyle } = restProps;
  return (
    <GradiationWrap {...restProps}>
      <Line />
      <NumberWrap>
        <div className="mask">
          <div className="wave-pulse wave-pulse-1"></div>
          <div className="wave-pulse wave-pulse-2"></div>
          <div className="wave-pulse wave-pulse-3"></div>
        </div>
        {number && <h6 className="number">{number}</h6>}
      </NumberWrap>
      <ContentWrap>
        {title && <Heading {...titleStyle}>{title}</Heading>}
        {desc && (
          <Text {...descStyle}>
            <ReactMarkdown>{desc}</ReactMarkdown>
          </Text>
        )}
      </ContentWrap>
    </GradiationWrap>
  );
};

Gradiation.defaultProps = {
  titleStyle: {},
  descStyle: {},
};

export default Gradiation;
