import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import StoriesPreview from 'components/blog/stories-preview';

const BlogArea = () => {
  const data = useStaticQuery(graphql`
    query HomePageStoriesQueryData {
      json: homePageStoriesJson {
        title
        subtitle
        button {
          link
          title
        }
        stories {
          id
          title
          slug
          excerpt
          postedDate
        }
      }
    }
  `);

  if (!data.json) {
    return null;
  }

  return <StoriesPreview {...data.json} />;
};

export default BlogArea;
