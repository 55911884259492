import React, { useState, useEffect } from 'react';
import Heading, { HeadingStyles } from 'components/ui/heading';
import Text from 'components/ui/text';
import parse from 'html-react-parser';
import ReactMarkdown from 'react-markdown';
import {
  BannerItem,
  BannerWrapper,
  DotsContainer,
  Dot,
  DotButtonWrapper,
} from './hero-banner.style';
import Button from 'components/ui/button';

export interface BannerItemType {
  title: string;
  body: string;
  logo?: {
    publicURL: string;
  };
  id: string;
  linkCTA?: string;
  linkURL?: string;
  button?: {
    link: string;
    title: string;
  };
}

const HeroBanner = ({
  bannerItems = [],
  titleStyle,
  textStyle,
  ctaStyle,
  ctaInnerStyle,
}: {
  bannerItems: BannerItemType[];
  titleStyle: Record<string, any>;
  textStyle: Record<string, any>;
  ctaStyle: Record<string, any>;
  ctaInnerStyle: HeadingStyles;
}) => {
  const [selectedItem, setSelectedItem] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setSelectedItem((item) => (item + 1) % bannerItems.length);
    }, 6000);
    return () => {
      clearInterval(interval);
    };
  }, [selectedItem, bannerItems, setSelectedItem]);

  return (
    <>
      <BannerWrapper id="carousel" aria-live="polite">
        {bannerItems.map(({ id, title, body, logo, button }, idx) => {
          const isSelected = selectedItem === idx;
          return (
            <BannerItem
              key={id}
              isSelected={isSelected}
              aria-hidden={isSelected ? 'false' : 'true'}
            >
              {logo && (
                <img
                  src={`${logo.publicURL}`}
                  className="hero-banner-img"
                  alt={logo.publicURL}
                />
              )}
              {title && <Heading {...titleStyle}>{parse(title) as string}</Heading>}
              {body && (
                <Text {...textStyle}>
                  <ReactMarkdown source={body} />
                </Text>
              )}
              {button && (
                <Button
                  to={button.link}
                  varient={'texted'}
                  className={'hero-banner-cta'}
                  {...ctaStyle}
                >
                  <Heading {...ctaInnerStyle}>{button.title}</Heading>
                </Button>
              )}
            </BannerItem>
          );
        })}
      </BannerWrapper>
      <DotsContainer aria-controls="carousel">
        {bannerItems.map(({ id }, idx) => {
          return (
            <DotButtonWrapper
              key={id}
              aria-label={`Select item #${idx}`}
              onClick={(e) => {
                e.preventDefault();
                setSelectedItem(idx);
              }}
            >
              <Dot filled={idx === selectedItem} />
            </DotButtonWrapper>
          );
        })}
      </DotsContainer>
    </>
  );
};

HeroBanner.defaultProps = {
  titleStyle: {
    as: 'h1',
    mb: '15px',
    fontweight: 500,
    color: 'white',
  },
  textStyle: {
    responsive: {
      small: {
        pb: '20px',
      },
    },
    color: 'white',
  },
  ctaStyle: {
    color: 'white',
    m: '15px 0 10px',
    fontSize: '20px',
    fontweight: 'bold',
    display: 'inline-block',
    textAlign: 'left',
    lineHeight: 1.2,
    skin: 'transparent',
  },
  ctaInnerStyle: {
    color: 'white',
    m: '15px 0 10px',
    pl: '5px',
    as: 'h5',
    fontweight: 'bold',
    display: 'inline-block',
    textAlign: 'left',
  },
};

export default HeroBanner;
