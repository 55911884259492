import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import parse from 'html-react-parser';
import ReactMarkdown from 'react-markdown';
import { Container, Row, Col } from 'components/ui/wrapper';
import Heading from 'components/ui/heading';
import { LogoContainer, LogosContainer, Section } from './portfolio-area.style';
import Button from 'components/ui/button';
import FadeIn from 'components/ui/fade-in';

interface Logo {
  id: string;
  link: string;
  image: {
    publicURL: string;
  };
}

interface LogoItem {
  id: string;
  link: string;
  logoId: string;
  image?: any;
}

const PortfolioArea = ({
  titleStyle,
}: {
  titleStyle: object;
  textStyle: object;
}) => {
  const data = useStaticQuery(graphql`
    query portfolioAreaQuery {
      logos: allLogosJson {
        nodes {
          id
          image {
            publicURL
          }
        }
      }
      json: homePagePortfolioJson {
        body
        id
        title
        button {
          link
          title
        }
        logos {
          id
          link
          logoId
        }
      }
    }
  `);

  if (!data?.json) {
    return null;
  }

  const { json } = data;

  const logos = json.logos.map((logoItem: LogoItem) => {
    const match = data.logos.nodes.find((logo: Logo) => logo.id === logoItem?.logoId);
    if (match) {
      logoItem.image = match.image;
    }
    return logoItem;
  });

  return (
    <Section>
      <Container>
        <Row alignitems="center">
          <Col>
            <LogosContainer>
              {(logos || []).map((logo: Logo) => (
                <LogoContainer key={logo.id}>
                  <FadeIn>
                    <a href={logo.link} target="__blank">
                      <img
                        src={logo.image.publicURL}
                        alt="portfolio item logo"
                        style={{ width: '100%' }}
                      />
                    </a>
                  </FadeIn>
                </LogoContainer>
              ))}
            </LogosContainer>
          </Col>
        </Row>
        <Row alignitems="center">
          <Col align="center" className="mt-5 offset-lg-2" lg={8}>
            {json.title && (
              <Heading {...titleStyle}>{parse(json.title) as string}</Heading>
            )}
            {json.body && <ReactMarkdown source={json.body} />}
            {json.button && (
              <Button to={json.button.link} varient="texted">
                {json.button.title}
              </Button>
            )}
          </Col>
        </Row>
      </Container>
    </Section>
  );
};

PortfolioArea.propTypes = {
  titleStyle: PropTypes.object,
};

PortfolioArea.defaultProps = {
  titleStyle: {
    as: 'h3',
    mb: '15px',
    fontweight: 500,
    child: {
      color: 'primary',
    },
  },
};

export default PortfolioArea;
