import React from 'react';
import SEO from 'components/seo';
import Layout from 'containers/layout/layout';
import Header from 'containers/layout/header';
import Footer from 'containers/layout/footer';
import HeroAboutWrap from 'containers/home-page/hero-about-wrap';
import Hero from 'containers/home-page/hero';
import AboutUsArea from 'containers/home-page/about-us-area';
import InvestmentsArea from 'containers/home-page/investments-area';
import PartnershipsArea from 'containers/home-page/partnerships-area';
import TimelineArea from 'containers/home-page/timeline-area';
import StoriesArea from 'containers/home-page/stories-area';
import PortfolioArea from 'containers/home-page/portfolio-area';

const IndexPage = ({ location }) => {
  return (
    <Layout location={location}>
      <SEO />
      <Header location={location} />
      <main className="site-wrapper-reveal">
        <HeroAboutWrap>
          <Hero />
        </HeroAboutWrap>
        <AboutUsArea />
        <PortfolioArea />
        <PartnershipsArea />
        <InvestmentsArea />
        <StoriesArea />
        <TimelineArea />
      </main>
      <Footer />
    </Layout>
  );
};

export default IndexPage;
