import React from 'react';
import ReactMarkdown from 'react-markdown';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Image from 'components/image';
import { Row, Col } from 'components/ui/wrapper';
import SectionTitle from 'components/ui/section-title';
import Button from 'components/ui/button';
import { PartnershipsAreaWrap } from './partnerships-area.style';

const PartnershipsArea = ({ sectionTitleStyle }) => {
  const data = useStaticQuery(graphql`
    query homePagePartnershipsQuery {
      json: homePagePartnershipsJson {
        image {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
              presentationHeight
            }
          }
        }
        content {
          title
          subtitle
          body
        }
        button {
          title
          link
        }
      }
    }
  `);

  const {
    content: { title, subtitle, body },
    button,
    image,
  } = data.json;

  return (
    <PartnershipsAreaWrap>
      <Row className="flex-lg-row-reverse align-items-center ml-0 mr-0">
        <Col lg={6} className="mx-sm-5 mx-lg-0 px-4">
          <SectionTitle
            title={title}
            subtitle={subtitle}
            {...sectionTitleStyle}
          />
          <ReactMarkdown source={body} />
          <Button to={button.link} varient="texted">
            {button.title}
          </Button>
        </Col>
        <Col lg={6} className="pr-0 pl-0 mt-5 mt-lg-0">
          <div>
            {image && (
              <Image
                fluid={image.childImageSharp.fluid}
                align="left"
                imgStyle={{ objectFit: 'contain' }}
              />
            )}
          </div>
        </Col>
      </Row>
    </PartnershipsAreaWrap>
  );
};

PartnershipsArea.propTypes = {
  sectionTitleStyle: PropTypes.object,
};

PartnershipsArea.defaultProps = {
  sectionTitleStyle: {
    mb: '30px',
    align: 'left',
  },
};

export default PartnershipsArea;
