import styled from 'styled-components';
import { device } from 'theme';

export const Section = styled.section`
  padding: 50px;
`;

export const LogosContainer = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-around;
  @media ${device.medium} {
    flex-wrap: wrap;
  }
`;

export const LogoContainer = styled.div`
  margin: 10px;
  display: flex;
  align-content: center;
  justify-content: center;
  @media ${device.medium} {
    flex: 1 0 34%;
  }
`;
